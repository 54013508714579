const hideAllPackages = () => {
  $('.packages-table .product-item .headline_row').each(function () {
    $(this).addClass('hidden')
  })
  $('.packages-table .product-item .details_row').each(function () {
    $(this).addClass('hidden')
  })
}

const filterPackages = (minVal, maxVal) => {
  hideAllPackages()
  showPackages(minVal, maxVal)
}

const showPackages = (minVal, maxVal) => {
  $('.packages-table .product-item .headline_row').each(function () {
    let price = parseInt($(this).attr('data-price'))
    if (minVal <= price && maxVal >= price) {
      $(this).removeClass('hidden')
    }
  })
  $('.packages-table .product-item .details_row').each(function () {
    let price = parseInt($(this).attr('data-price'))
    if (minVal <= price && maxVal >= price) {
      $(this).removeClass('hidden')
    }
  })
}

$(document)
  .on('change', '.container-fluid .slider__min-slider', function () {
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($(this).val())
    const maxVal = parseInt($('.slider__max-slider').val())

    filterPackages(minVal, maxVal)
  })

  .on('keyup', '.container-fluid .min-slider', function (event) {
    if (event.keyCode == 13) {
      const minVal = parseInt($(this).val().slice(1))
      const maxVal = parseInt($('.slider__max-slider').val())
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackages(minVal, maxVal)
    }
  })

  .on('change', '.container-fluid .slider__max-slider', function () {
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($('.slider__min-slider').val())
    const maxVal = parseInt($(this).val())

    filterPackages(minVal, maxVal)
  })

  .on('keyup', '.container-fluid .max-slider', function (event) {
    if (event.keyCode == 13) {
      const minVal = parseInt($('.slider__min-slider').val())
      const maxVal = parseInt($(this).val().slice(1))
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackages(minVal, maxVal)
    }
  })


