<template>
  <span class="currency">
    <CurrencyDropdown
      :currency="currency"
      @currency-emit="updateCurrency"
    />
  </span>
</template>

<script>
import CurrencyDropdown from '../../default/currency_convert/currency-dropdown'

export default {
  components: {
    CurrencyDropdown
  },

  // data() {
  //   return {
  //     currency: 'USD'
  //   }
  // },

  computed: {
    currency(){
      return this.$store.state.event.formCurrency
    },

    rate(){
      return this.$store.state.event.formRate
    },
  },

  methods: {
    updateCurrency(errors, currency, rate) {
      if (currency && rate) {
        this.$store.state.event.formCurrency = currency
        this.$store.state.event.formRate = rate
      }
    }
  }
}
</script>
